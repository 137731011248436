// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCOiX0iuuO3SO_v3ZOC7H0IS7EDh0q--YY",
  authDomain: "riseapp-c6876.firebaseapp.com",
  projectId: "riseapp-c6876",
  storageBucket: "riseapp-c6876.appspot.com",
  messagingSenderId: "86347512082",
  appId: "1:86347512082:web:1cb0a249e34ab19dd4dbcb",
  measurementId: "G-59R6NBQYW6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
// eslint-disable-next-line
const analytics = getAnalytics(app);

export {auth, app , db , storage} ;